import React, { useState } from "react";

import DecisionSupport from "./decision-support";
import AboutPopup from "./decision-support/popups/about-popup";

import { Avatar, Badge, Button, Container } from "../../../";
import { FacebookFilled, ScheduleOutlined, PhoneOutlined } from "../../../";
import AgentRecommendationPopup from "../agent-recommendation-popup";

import css from "./style.module.scss";
import _ from "lodash";
import { FormOutlined } from "@ant-design/icons";

export default ({
  data,
  mobileActiveContainer,
  onPicwellOn,
  onPicwellOff,
  enabled,
  onSave,
  questions,
  openRecommendations,
}) => {
  const [aboutPopupVisible, setAboutPopupVisible] = useState(false);
  const [
    agentRecommendationPopupVisible,
    setAgentRecommendationPopupVisible,
  ] = useState(false);

  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);
  const openAgentRecommendationPopup = () =>
    setAgentRecommendationPopupVisible(true);
  const closeAgentRecommendationPopup = () =>
    setAgentRecommendationPopupVisible(false);

  openRecommendations && openRecommendations(openAgentRecommendationPopup);

  return (
    <Container
      className={`${css.rightBarContainer} ${
        mobileActiveContainer !== "user-info" ? "hidden-on-mobile" : ""
      }`}
    >
      <Container className={css.avatarContainer}>
        <Avatar
          onPress={openAboutPopup}
          link=""
          round="true"
          src={data?.site?.siteMetadata?.agent?.imageSrc}
        />

        <Container className={css.agentName}>
          {data?.site?.siteMetadata?.agent?.name}
        </Container>
        <Container onPress={openAboutPopup} className={css.aboutLink}>
          Show Bio
        </Container>
      </Container>
      {
        <Container className={`${css.contactSupportContainer}`}>
          <Container className={css.contactSupport}>
            <Container className={css.msg1}>Need decision support?</Container>

            <Container
              onPress={openAgentRecommendationPopup}
              className={css.recommendationButton}
            >
              {_.isEmpty(questions) ? (
                <>
                  Have {data?.site?.siteMetadata?.agent?.name} <br /> recommend
                  a plan
                </>
              ) : (
                <>
                  <FormOutlined />
                  Edit Answers
                </>
              )}
            </Container>

            <Container className={css.contactCard}>
              <Container className={css.agentInfo}>
                <svg className={css.phone}>
                  <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                </svg>

                <span>{data.site.siteMetadata.agent.phone}</span>
              </Container>

              {data?.site?.siteMetadata?.agent?.calendly && (
                <a
                  target="_blank"
                  href={data?.site?.siteMetadata?.agent?.calendly}
                >
                  <Container className={css.agentInfo}>
                    <svg>
                      <use xlinkHref="/sprite.svg#schedule-call-icon" />
                    </svg>
                    <span>Schedule a Call</span>
                  </Container>
                </a>
              )}
              <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>
                <Container className={css.agentInfo}>
                  <svg>
                    <use xlinkHref="/sprite.svg#facebook-icon" />
                  </svg>
                  <span>Send Message</span>
                </Container>
              </a>
            </Container>
          </Container>
        </Container>
      }
      {
        <DecisionSupport
          enabled={enabled}
          onPicwellOn={onPicwellOn}
          onPicwellOff={onPicwellOff}
          onSubmit={onPicwellOn}
        />
      }
      <AboutPopup
        data={data}
        visible={aboutPopupVisible}
        onClose={closeAboutPopup}
      />
      <AgentRecommendationPopup
        {...questions}
        onSave={(q) => {
          onSave && onSave(q);
        }}
        data={data}
        visible={agentRecommendationPopupVisible}
        onClose={closeAgentRecommendationPopup}
      />
      {false && (
        <>
          <Container className={css.panelContainer}>
            <Container className={css.panelHeader}>Compare Plans</Container>
            <Container className={css.panelContent}>
              <Container></Container>
              <Container className={css.compareButton}>
                <Button type="primary">Compare Quote</Button>
              </Container>
            </Container>
          </Container>
          <Container className={css.panelContainer}>
            <Container className={css.panelHeader}>Your Cart</Container>
            <Container className={css.panelContent}>
              <Container></Container>
              <Container className={css.compareButton}></Container>
            </Container>
          </Container>
        </>
      )}
    </Container>
  );
};
