import React, { useState } from "react";

import { Radio } from "antd";

import { Container } from "..";

import css from "./style.module.scss";

export default ({ value, setValue, children }) => {
  return (
    <Container
      onPress={() => {
        setValue();
      }}
      className={`${css.radioButtonContainer} ${value ? css.selected : ""} `}
    >
      <Container className={css.radioContainer}>
        <Radio onChange={setValue} checked={value} />
      </Container>
      <Container>{children}</Container>
    </Container>
  );
};
