import React, { useState, useRef, useContext } from "react";

import { Container } from "../../components";
import { DatePicker } from "antd";
import moment from "moment";

import css from "./style.module.scss";
import { PlatformContext } from "../../context";

export default (props) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);
  const platform = useContext(PlatformContext);

  return (
    <Container className={`${css.datePickerContainer} ${props.className}`}>
      <Container>
        <DatePicker
          value={props.value}
          onOpenChange={() => {
            setFocused(!focused);
          }}
          dropdownClassName={css.calendar}
          defaultPickerValue={
            props.defaultPickerValue ? props.defaultPickerValue : undefined
          }
          onChange={(e) => {
            props.onChange && props.onChange(e);
          }}
          placeholder=""
          className={`${css.datePicker} ${
            platform.enrollment
              ? focused
                ? "box-shadow"
                : "box-shadow-none"
              : ""
          }`}
          ref={ref}
        />
      </Container>
      {/* 
      {(focused || (props.value && props.value != "")) && (
        <Container className={css.placeholder}>{props.focusedlabel}</Container>
      )} */}

      {props.before && (
        <Container
          onPress={() => {
            ref.current.focus();
          }}
          className={`${css.before} ${
            props.before && props.value ? css.active : ""
          }`}
        >
          {props.before}
        </Container>
      )}
    </Container>
  );
};
