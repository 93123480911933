import React, { useState, useEffect } from "react";

import { Container, ProductCard, TextInput, Icon } from "../../../";

import css from "./style.module.scss";

import OfferCard from "../offer-card";

import { Button as AntdButton, Collapse } from "antd";

import axios from "axios";

import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/dotted-papper.json";

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const { Panel } = Collapse;

export default (props) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const { results, selectedItems, sendQuote, finishEnrollment } = props;
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(true);
  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
      setFadeOut(false);
    });

    new Promise((resolve) => setTimeout(resolve, 2600)).then(() => {
      setLoading(false);
    });
  }, []);

  const nationalGeneralPlans = results.plans["NATIONAL_GENERAL"];

  const marketPlacePlans = results.plans["MARKETPLACE"];

  return loading ? (
    <Container className={css.cartLoadingAnimation}>
      <Lottie
        loop
        animationData={lottieJson}
        play
        className={`${fadeOut ? css.visible : css.hidden}`}
      />
    </Container>
  ) : (
    <>
      {selectedItems.size > 0 && (
        <Container className={css.thirdStep}>
          <Container className={css.cardHeader}>Your Cart</Container>

          <Container className={css.finalFormContainer}>
            <Container className={css.finalFormSummary}>
              {selectedItems &&
                [...selectedItems].some((s) =>
                  nationalGeneralPlans.some((d) => d.id == "" + s)
                ) && (
                  <Container className={css.planTypeContainer}>
                    <Container className={css.planType}>
                      National General
                    </Container>
                    <Collapse ghost>
                      {[...selectedItems].map((s) => {
                        const plan = nationalGeneralPlans.filter(
                          (f) => f.id == "" + s
                        )[0];

                        if (!plan) {
                          return null;
                        }

                        return (
                          <Panel
                            showArrow={false}
                            key={plan.id}
                            header={<ProductCard plan={plan} />}
                          >
                            <Container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <OfferCard hideHeader {...plan} />
                            </Container>
                          </Panel>
                        );
                      })}
                    </Collapse>
                    {selectedItems &&
                      [...selectedItems].some((s) =>
                        nationalGeneralPlans.some((d) => d.id == "" + s)
                      ) && (
                        <Container
                          style={{
                            bottom: "0px",
                            width: "100%",
                            padding: "0px 36px",
                          }}
                          className={css.totalPremiumContainer}
                        >
                          <Container className={css.totalPremium}>
                            Total premium
                          </Container>
                          <Container className={`${css.totalPremiumValue}`}>
                            ${" "}
                            {results.plans["NATIONAL_GENERAL"]
                              .filter((f) => selectedItems.has(f.id))
                              .reduce((a, b) => {
                                return a + b.premium.value;
                              }, 0)
                              .toFixed(2)}
                          </Container>
                        </Container>
                      )}
                    <Container className={css.sendButton}>
                      <AntdButton
                        onClick={() => {
                          finishEnrollment &&
                            finishEnrollment(firstName, lastName, email, phone);
                        }}
                        type="primary"
                      >
                        <Icon
                          style={{ width: "36px", height: "36px" }}
                          fill="#fff"
                          icon="enrollment-cart"
                        />
                        Start Your Enrollment
                      </AntdButton>
                    </Container>
                  </Container>
                )}

              {selectedItems &&
                [...selectedItems].some((s) =>
                  marketPlacePlans.some((d) => d.id == "" + s)
                ) && (
                  <Container
                    style={{ marginBottom: "12px" }}
                    className={css.planTypeContainer}
                  >
                    <Container className={css.planType}>Marketplace</Container>
                    <Collapse ghost>
                      {[...selectedItems].map((s) => {
                        const plan = marketPlacePlans.filter(
                          (f) => f.id == "" + s
                        )[0];
                        if (!plan) {
                          return null;
                        }

                        return (
                          <Panel
                            showArrow={false}
                            key={plan.id}
                            header={<ProductCard plan={plan} />}
                          >
                            <Container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <OfferCard hideHeader {...plan} />
                            </Container>
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </Container>
                )}
              {selectedItems &&
                [...selectedItems].some((s) =>
                  marketPlacePlans.some((d) => d.id == "" + s)
                ) && (
                  <Container
                    style={{
                      bottom: "0px",
                      width: "100%",
                      padding: "0px 36px",
                    }}
                    className={css.totalPremiumContainer}
                  >
                    <Container className={css.totalPremium}>
                      Total premium
                    </Container>
                    <Container className={`${css.totalPremiumValue}`}>
                      ${" "}
                      {results.plans["MARKETPLACE"]
                        .filter((f) => selectedItems.has(f.id))
                        .reduce((a, b) => {
                          return a + b.premium.value;
                        }, 0)
                        .toFixed(2)}
                    </Container>
                  </Container>
                )}
            </Container>
          </Container>
        </Container>
      )}
    </>
  );
};
