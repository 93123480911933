import React, { useState, useEffect } from "react";

import { Container, ProductCard, TextInput, Icon } from "../../../";

import css from "./style.module.scss";

import OfferCard from "../offer-card";

import { Button as AntdButton, Collapse } from "antd";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/dotted-papper.json";

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const { Panel } = Collapse;

export default (props) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const { results, selectedItems, sendQuote } = props;
  const medicalPlans = results.plans["MEDICAL"];
  const dentalPlans = results.plans["DENTAL"];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Container>
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <>
      {selectedItems.size > 0 && (
        <Container className={css.thirdStep}>
          <Container className={css.cardHeader}>Your Cart</Container>

          <Container className={css.finalFormContainer}>
            <Container className={css.finalFormSummary}>
              {selectedItems &&
                [...selectedItems].some((s) =>
                  medicalPlans.some((d) => d.id == "" + s)
                ) && (
                  <Container className={css.planTypeContainer}>
                    <Container className={css.planType}>Medical</Container>
                    <Collapse ghost>
                      {[...selectedItems].map((s) => {
                        const plan = medicalPlans.filter(
                          (f) => f.id == "" + s
                        )[0];
                        if (!plan) {
                          return null;
                        }
                        return (
                          <Panel
                            showArrow={false}
                            key={plan.id}
                            header={<ProductCard plan={plan} />}
                          >
                            <Container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <OfferCard hideHeader {...plan} />
                            </Container>
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </Container>
                )}
              {selectedItems &&
                [...selectedItems].some((s) =>
                  dentalPlans.some((d) => d.id == "" + s)
                ) && (
                  <Container
                    style={{ marginBottom: "12px" }}
                    className={css.planTypeContainer}
                  >
                    <Container className={css.planType}>Dental</Container>
                    <Collapse ghost>
                      {[...selectedItems].map((s) => {
                        const plan = dentalPlans.filter(
                          (f) => f.id == "" + s
                        )[0];
                        if (!plan) {
                          return null;
                        }

                        return (
                          <Panel
                            showArrow={false}
                            key={plan.id}
                            header={<ProductCard plan={plan} />}
                          >
                            <Container
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <OfferCard hideHeader {...plan} />
                            </Container>
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </Container>
                )}
              {
                <Container
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    width: "100%",
                    padding: "0px 36px",
                  }}
                  className={css.totalPremiumContainer}
                >
                  <Container className={css.totalPremium}>
                    Total premium
                  </Container>
                  <Container className={`${css.totalPremiumValue}`}>
                    ${" "}
                    {results.plans["ALL"]
                      .filter((f) => selectedItems.has(f.id))
                      .reduce((a, b) => {
                        return a + b.premium.value;
                      }, 0)
                      .toFixed(2)}
                  </Container>
                </Container>
              }
            </Container>
          </Container>
        </Container>
      )}
      <Container className={css.thirdStep}>
        <Container className={css.finalForm}>
          <Container className={css.cardHeader}>Contact Info</Container>
          <Container className={css.toEnroll}>
            We will contact you and help to enroll the best option(s).
          </Container>
          <Container className={css.finalFormInputs}>
            <TextInput
              value={name}
              onChange={(e) => {
                setName(handleInputChange(e));
              }}
              containerclassname={css.finalFormInputText}
              before="Name :"
            />
            <TextInput
              value={email}
              onChange={(e) => {
                setEmail(handleInputChange(e));
              }}
              containerclassname={css.finalFormInputText}
              before="Email :"
            />
            <TextInput
              value={phone}
              onChange={(e) => {
                setPhone(handleInputChange(e));
              }}
              containerclassname={css.finalFormInputText}
              before="Phone :"
            />
            <Container className={css.sendButton}>
              <AntdButton
                disabled={!name || !email || !phone}
                onClick={() => {
                  sendQuote && sendQuote(name, "", email, phone);
                }}
                type="primary"
              >
                Submit
              </AntdButton>
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  );
};
