import React, { useState } from "react";

import {
  Container,
  TextInput,
  Icon,
  RadioButton,
  ProductCard,
  OfferCard,
  Select,
  Popup,
} from "../../../";

import css from "./style.module.scss";

import { Button as AntdButton, Button, Collapse } from "antd";

import axios from "axios";
import { redirectTo } from "@reach/router";
import dental from "../offer-card/benefits-list/dental";

const aspire_keys = [
  {
    "Scott Eckley":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20285&affiliateId=170998",
  },
  {
    "Jared Boyett":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20867&affiliateId=170998",
  },
  {
    "Matt Safranek":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20868&affiliateId=170998",
  },
  {
    "Marq Hughes":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20869&affiliateId=170998",
  },
  {
    "Luke Eckley":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20870&affiliateId=170998",
  },
  {
    "Jordan Eckley":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20871&affiliateId=170998",
  },
  {
    "Oliver Byers":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20872&affiliateId=170998",
  },
  {
    "Derek Grayson":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20873&affiliateId=170998",
  },
  {
    "Phil Kathol":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20874&affiliateId=170998",
  },
  {
    "Brendan Lay":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20875&affiliateId=170998",
  },
  {
    "Mike Occhipinto":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20876&affiliateId=170998",
  },
  {
    "Zach Gensky":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20877&affiliateId=170998",
  },
  {
    "Austyn Bair":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20878&affiliateId=170998",
  },
  {
    "Jack Macgowan":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20879&affiliateId=170998",
  },
  {
    "Jeff Gueldner":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20880&affiliateId=170998",
  },
  {
    "Liz Friedrichsen":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20881&affiliateId=170998",
  },
  {
    "George Kopp":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20882&affiliateId=170998",
  },
  {
    "Tom Albers":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20883&affiliateId=170998",
  },
  {
    "Andrew Loberg":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20884&affiliateId=170998",
  },
  {
    "Christian Bickley":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20885&affiliateId=170998",
  },
  {
    "Matt Davis":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20886&affiliateId=170998",
  },
  {
    "Ray Dorsey":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20887&affiliateId=170998",
  },
  {
    "Austin Zimmerman":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20888&affiliateId=170998",
  },
  {
    "Jonah Meleski":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20889&affiliateId=170998",
  },
  {
    "Adam Ledbetter":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20890&affiliateId=170998",
  },
  {
    "Matt Peebles":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20891&affiliateId=170998",
  },
  {
    "Carter Barajas":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20892&affiliateId=170998",
  },
  {
    "Matt Sisk":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20893&affiliateId=170998",
  },
  {
    "Jacob Katzfey":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20894&affiliateId=170998",
  },
  {
    "Dane Freberg":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20895&affiliateId=170998",
  },
  {
    "Tanner Arst":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20896&affiliateId=170998",
  },
  {
    "Jacob Cooper":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20897&affiliateId=170998",
  },
  {
    "Alex Karrenbrock":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20898&affiliateId=170998",
  },
  {
    "Adam Siplon":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20899&affiliateId=170998",
  },
  {
    "Miguel Morales":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20900&affiliateId=170998",
  },
  {
    "Sienna Spiglanin":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20901&affiliateId=170998",
  },
  {
    "Rich Robertaccio":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20902&affiliateId=170998",
  },
  {
    "Natalie Robertaccio":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20903&affiliateId=170998",
  },
  {
    "Lindsay Pickering":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20904&affiliateId=170998",
  },
  {
    "Joe Mailander":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20905&affiliateId=170998",
  },
  {
    "Clark Lynch":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20906&affiliateId=170998",
  },
  {
    "Brandon Weis":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20907&affiliateId=170998",
  },
  {
    "Thelma Arevalo":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20912&affiliateId=170998",
  },
  {
    "Michael Zulkowski":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20945&affiliateId=170998",
  },
  {
    "Michele Painter":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21012&affiliateId=170998",
  },
  {
    "Matt Safranek":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20289&affiliateId=170998",
  },
  {
    "Robert Powell":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20617&affiliateId=170998",
  },
  {
    "Richard Campbell":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20273&affiliateId=170998",
  },
  {
    "Steve Neu":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20251&affiliateId=170998",
  },
  {
    "Jonathan Longwell":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21146&affiliateId=170998",
  },
  {
    "Isaac Zitterkopf":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21156&affiliateId=170998",
  },
  {
    "Talbott Buford":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21158&affiliateId=170998",
  },
  {
    "Antonio Torres":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21159&affiliateId=170998",
  },
  {
    "Trevor Actkinson":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21160&affiliateId=170998",
  },
  {
    "Mallory Lease":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21161&affiliateId=170998",
  },
  {
    "Abby Brockhaus":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21162&affiliateId=170998",
  },
  {
    "Christian Roduner":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21163&affiliateId=170998",
  },
  {
    "Eli Kistler":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21164&affiliateId=170998",
  },
  {
    "Drew Nangle":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21165&affiliateId=170998",
  },
  {
    "Steven Raines":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21173&affiliateId=170998",
  },
  {
    "Liz Magnussen":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21181&affiliateId=170998",
  },
  {
    "Alex Butler":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21206&affiliateId=170998",
  },
  {
    "Andrea Escalante":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21216&affiliateId=170998",
  },
  {
    "Joseph Durnell":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21217&affiliateId=170998",
  },
  {
    "Brigham Mooney":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21224&affiliateId=170998",
  },
  {
    "Austyn Bair":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21228&affiliateId=170998",
  },
  {
    "Dominic Albera":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21233&affiliateId=170998",
  },
  {
    "Phil Saglietto":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21241&affiliateId=170998",
  },
  {
    "Devon Lee":
      "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-21244&affiliateId=170998",
  },
];

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const { Panel } = Collapse;

const metalLevelColors = {
  Bronze: "#6A3805",
  Catastrophic: "#575988",
  Gold: "#AF9500",
  Platinum: "#3B3B41",
  Silver: "#B4B4B4",
};

export default (props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [dentalKey, setDentalKey] = useState(
    "https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=AFR-20285&affiliateId=170998"
  );
  const { startEnrollment } = props;

  const [enrollmentEnabled, setEnrollment] = useState(true);

  const { results, selectedItems, sendQuote, finishEnrollment } = props;
  const nationalGeneralPlans = results.plans["NATIONAL_GENERAL"];

  const marketPlacePlans = results.plans["MARKETPLACE"];

  const aspireDentalPlans = results.plans["ASPIRE_DENTAL"];

  const isDisabled = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      return true;
    }

    return !firstName || !lastName || !email || !phone;
  };

  return (
    <>
      <Container className={css.thirdStep}>
        <Container className={css.finalForm}>
          <Container className={css.cardHeader}>Checkout</Container>

          <Container className={css.finalFormInputs}>
            <Container className={css.whatToDoNext}>Contact info:</Container>

            <Container className={css.finalFormInput}>
              <TextInput
                value={firstName}
                onChange={(e) => {
                  setFirstName(handleInputChange(e));
                }}
                containerclassname={css.finalFormInputText}
                before="First Name :"
              />
            </Container>
            <Container className={css.finalFormInput}>
              <TextInput
                value={lastName}
                onChange={(e) => {
                  setLastName(handleInputChange(e));
                }}
                containerclassname={css.finalFormInputText}
                before="Last Name :"
              />
            </Container>
            <Container className={css.finalFormInput}>
              <TextInput
                value={email}
                onChange={(e) => {
                  setEmail(handleInputChange(e));
                }}
                containerclassname={css.finalFormInputText}
                mask={
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }
                before="Email :"
              />
            </Container>
            <Container className={css.finalFormInput}>
              <TextInput
                value={phone}
                onChange={(e) => {
                  const x = e.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                  e.target.value = !x[2]
                    ? x[1]
                    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

                  setPhone(handleInputChange(e));
                }}
                containerclassname={css.finalFormInputText}
                mask={/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/}
                before="Phone :"
              />
            </Container>
            <Container className={css.toEnroll}>
              <Container className={css.whatToDoNextContainer}>
                <Container className={css.whatToDoNext}>
                  What you want to do next?
                </Container>

                <Container className={css.optionsContainer}>
                  <Container className={css.radioButtonCard1}>
                    <RadioButton
                      value={enrollmentEnabled}
                      setValue={() => setEnrollment(!enrollmentEnabled)}
                    >
                      <Container className={css.enrollmentTitle}>
                        Go to Enrollment
                      </Container>
                      <Container className={css.enrollmentDescription}>
                        You will be redirect to a system to verify your
                        eligibility and enroll the plans you selected.
                      </Container>
                    </RadioButton>
                  </Container>
                  <Container className={css.radioButtonCard2}>
                    <RadioButton
                      value={!enrollmentEnabled}
                      setValue={() => setEnrollment(!enrollmentEnabled)}
                    >
                      <Container className={css.enrollmentTitle}>
                        Speak with an agent
                      </Container>
                      <Container className={css.enrollmentDescription}>
                        Receive a summary of the plan(s) you have selected and
                        have a licensed agent contact you.
                      </Container>
                    </RadioButton>
                  </Container>
                </Container>
              </Container>
            </Container>

            {enrollmentEnabled && (
              <Container className={css.finalFormSummary}>
                {selectedItems &&
                  [...selectedItems].some((s) =>
                    nationalGeneralPlans.some((d) => d.id == "" + s)
                  ) && (
                    <Container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      className={css.planTypeContainer}
                    >
                      <Container className={css.planType}>
                        National General
                      </Container>
                      <Collapse ghost>
                        {[...selectedItems].map((s) => {
                          const plan = nationalGeneralPlans.filter(
                            (f) => f.id == "" + s
                          )[0];

                          if (!plan) {
                            return null;
                          }

                          return (
                            <Panel
                              showArrow={false}
                              key={plan.id}
                              header={
                                <ProductCard
                                  plan={{
                                    ...plan,
                                    premium: {
                                      value: props.specialRates
                                        ? props.specialRates.priorCoverage
                                          ? props.specialRates.prefferedRate
                                            ? plan.premium_options.options[2]
                                                .premium
                                            : plan.premium_options.options[1]
                                                .premium
                                          : plan.premium_options.options[0]
                                              .premium
                                        : plan.premium_options.options[0]
                                            .premium,
                                    },
                                  }}
                                />
                              }
                            >
                              <Container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <OfferCard hideHeader {...plan} />
                              </Container>
                            </Panel>
                          );
                        })}
                      </Collapse>
                      {selectedItems &&
                        [...selectedItems].some((s) =>
                          nationalGeneralPlans.some((d) => d.id == "" + s)
                        ) && (
                          <Container
                            style={{
                              bottom: "0px",
                              width: "100%",
                              padding: "0px 36px",
                            }}
                            className={css.totalPremiumContainer}
                          >
                            <Container className={css.totalPremium}>
                              Total premium
                            </Container>
                            <Container className={`${css.totalPremiumValue}`}>
                              ${" "}
                              {results.plans["NATIONAL_GENERAL"]
                                .filter((f) => selectedItems.has(f.id))
                                .reduce((a, b) => {
                                  const value = props.specialRates
                                    ? props.specialRates.priorCoverage
                                      ? props.specialRates.prefferedRate
                                        ? b.premium_options.options[2].premium
                                        : b.premium_options.options[1].premium
                                      : b.premium_options.options[0].premium
                                    : b.premium_options.options[0].premium;
                                  return a + value;
                                }, 0)
                                .toFixed(2)}
                            </Container>
                          </Container>
                        )}
                      <Container className={css.sendButton}>
                        <AntdButton
                          disabled={isDisabled()}
                          onClick={() => {
                            finishEnrollment &&
                              finishEnrollment(
                                firstName,
                                lastName,
                                email,
                                phone
                              );
                          }}
                          type="primary"
                        >
                          <Icon
                            style={{ width: "36px", height: "36px" }}
                            fill={
                              !firstName || !lastName || !email || !phone
                                ? "#ccc"
                                : "#fff"
                            }
                            icon="enrollment-cart"
                          />
                          Start Your Enrollment
                        </AntdButton>
                      </Container>
                    </Container>
                  )}
              </Container>
            )}

            {enrollmentEnabled && (
              <Container className={css.finalFormSummary}>
                {selectedItems &&
                  [...selectedItems].some((s) =>
                    marketPlacePlans.some((d) => d.id == "" + s)
                  ) && (
                    <Container className={css.planTypeContainer}>
                      <Container className={css.planType}>
                        Marketplace
                      </Container>
                      <Collapse ghost>
                        {[...selectedItems].map((s) => {
                          const plan = marketPlacePlans.filter(
                            (f) => f.id == "" + s
                          )[0];

                          if (!plan) {
                            return null;
                          }

                          return (
                            <Panel
                              showArrow={false}
                              key={plan.id}
                              header={<ProductCard plan={plan} />}
                            >
                              <Container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <OfferCard hideHeader {...plan} />
                              </Container>
                            </Panel>
                          );
                        })}
                      </Collapse>
                      {selectedItems &&
                        [...selectedItems].some((s) =>
                          marketPlacePlans.some((d) => d.id == "" + s)
                        ) && (
                          <Container
                            style={{
                              bottom: "0px",
                              width: "100%",
                              padding: "0px 36px",
                            }}
                            className={css.totalPremiumContainer}
                          >
                            <Container className={css.totalPremium}>
                              Total premium
                            </Container>
                            <Container className={`${css.totalPremiumValue}`}>
                              ${" "}
                              {results.plans["MARKETPLACE"]
                                .filter((f) => selectedItems.has(f.id))
                                .reduce((a, b) => {
                                  return a + b.premium.value;
                                }, 0)
                                .toFixed(2)}
                            </Container>
                          </Container>
                        )}
                      <Container className={css.sendButton}>
                        <AntdButton
                          onClick={() => {
                            results.plans["MARKETPLACE"]
                              .filter((f) => selectedItems.has(f.id))
                              .forEach(
                                (p) =>
                                  props.redirectToHealthSherpa &&
                                  props.redirectToHealthSherpa(p)
                              );
                          }}
                          type="primary"
                        >
                          <Icon
                            style={{ width: "36px", height: "36px" }}
                            fill="#fff"
                            icon="enrollment-cart"
                          />
                          Start Your Enrollment
                        </AntdButton>
                      </Container>
                    </Container>
                  )}
              </Container>
            )}

            {enrollmentEnabled && (
              <Container className={css.finalFormSummary}>
                {selectedItems &&
                  [...selectedItems].some((s) =>
                    aspireDentalPlans.some((d) => d.id == "" + s)
                  ) && (
                    <Container className={css.planTypeContainer}>
                      <Container className={css.planType}>
                        ASPIRE DENTAL
                      </Container>
                      <Collapse ghost>
                        {[...selectedItems].map((s) => {
                          const plan = aspireDentalPlans.filter(
                            (f) => f.id == "" + s
                          )[0];

                          if (!plan) {
                            return null;
                          }

                          return (
                            <Panel
                              showArrow={false}
                              key={plan.id}
                              header={<ProductCard plan={plan} />}
                            >
                              <Container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <OfferCard hideHeader {...plan} />
                              </Container>
                            </Panel>
                          );
                        })}
                      </Collapse>
                      <Container style={{ maxWidth: "480px", margin: "auto" }}>
                        <Select
                          value={dentalKey}
                          size="large"
                          style={{ width: "480px", margin: "auto" }}
                          before="Select an agent"
                          onChange={(e) => {
                            setDentalKey(e);
                          }}
                          options={aspire_keys.map((o) => {
                            const label = Object.keys(o)[0];
                            const value = o[label];

                            return {
                              label,
                              value,
                            };
                          })}
                        />
                      </Container>
                      {selectedItems &&
                        [...selectedItems].some((s) =>
                          aspireDentalPlans.some((d) => d.id == "" + s)
                        ) && (
                          <Container
                            style={{
                              bottom: "0px",
                              width: "100%",
                              padding: "0px 36px",
                            }}
                            className={css.totalPremiumContainer}
                          >
                            <Container className={css.totalPremium}>
                              Total premium
                            </Container>
                            <Container className={`${css.totalPremiumValue}`}>
                              ${" "}
                              {results.plans["ASPIRE_DENTAL"]
                                .filter((f) => selectedItems.has(f.id))
                                .reduce((a, b) => {
                                  return a + b.premium.value;
                                }, 0)
                                .toFixed(2)}
                            </Container>
                          </Container>
                        )}
                      <Container className={css.sendButton}>
                        <AntdButton
                          onClick={() => {
                            setPopupVisible(true);
                          }}
                          type="primary"
                        >
                          <Icon
                            style={{ width: "36px", height: "36px" }}
                            fill="#fff"
                            icon="enrollment-cart"
                          />
                          Start Your Enrollment
                        </AntdButton>
                      </Container>
                    </Container>
                  )}
              </Container>
            )}

            <Container className={css.backButton}>
              <Button
                onClick={() => {
                  props.goBack && props.goBack();
                }}
                type="primary"
              >
                Back
              </Button>
            </Container>

            {!enrollmentEnabled && (
              <Container className={css.sendButton}>
                <AntdButton
                  onClick={() => {
                    // props.redirectToHealthSherpa && props.redirectToredirectToHealthSherpa();
                  }}
                  type="primary"
                >
                  <Icon
                    style={{ width: "36px", height: "36px" }}
                    fill="#fff"
                    icon="submit-your-quote"
                  />
                  Submit Your Quote
                </AntdButton>
              </Container>
            )}
          </Container>
        </Container>
      </Container>
      <Popup
        title={"Aspire Dental"}
        className={css.popupContainer}
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
        openOutside={dentalKey}
      >
        <iframe style={{ width: "100%" }} src={dentalKey} />
      </Popup>
    </>
  );
};
