import React, { useState, useRef, useContext } from "react";
import css from "./style.module.scss";

import { Input } from "antd";

import { Container } from "..";
import { PlatformContext } from "../../context";

export default (props) => {
  const platform = useContext(PlatformContext);
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);

  const inputProps = { ...props };
  delete inputProps.beforeClassName;
  delete inputProps.beforeWidth;
  delete inputProps.notenrollment;

  return (
    <Container
      style={{ width: "100%" }}
      lang="en-US"
      className={`${css.relative} ${props.className}`}
    >
      <Input
        ref={ref}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        className={`${css.apolloInput} ${
          platform?.enrollment ? css.enrollmentInput : ""
        } ${props.before ? css.end : ""} ${
          platform.enrollment
            ? focused
              ? css.invertedShadow
              : css.invertShadow
            : ""
        }`}
        {...inputProps}
      />
      {focused || props.value == 0 || (props.value && props.value != "") ? (
        <Container
          className={`${css.placeholder} ${
            !props.notenrollment && platform?.enrollment
              ? css.enrollmentPlaceholder
              : ""
              
          } 
          
          ${                !focused && props.mask &&
              typeof props.value == "string" &&
              props.value != "" &&
              !props.mask.test(props.value)
              ? css.invalidFocusedLabel
              : ""}
          
          `}
        >
          {props.focusedlabel}
        </Container>
      ) : null}

      {props.before && (
        <Container
          onPress={() => {
            ref.current.focus();
          }}
          className={`${css.before} ${
            props.before && props.value ? css.active : ""
          }
            ${props.beforeClassName ? props.beforeClassName : ""}
            ${platform?.enrollment ? css.enrollmentBefore : ""} ${
            !focused &&
            props.mask &&
            typeof props.value == "string" &&
            props.value != "" &&
            !props.mask.test(props.value)
              ? css.invalidMask
              : ""
          }`}
        >
          {props.before}
        </Container>
      )}
    </Container>
  );
};
