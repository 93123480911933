import React, { useState, useEffect } from "react";

import { graphql, useStaticQuery, Link } from "gatsby";
import {
  QuestionCircleOutlined,
  AddDependentInput,
  Button,
  Checkbox,
  Container,
  DatePicker,
  TextInput,
  Select,
  DownOutlined,
  WomanOutlined,
  ManOutlined,
  SelectOption,
  UserAddOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from "../../components";
import { Calendar, Popover } from "antd";
import css from "./style.module.scss";
import axios from "axios";

import _ from "lodash";
import { connect } from "react-redux";

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const subsidies = {
  "1": 12880,
  "2": 17420,
  "3": 21960,
  "4": 26500,
  "5": 31040,
  "6": 35580,
  "7": 40120,
  "8": 44660,
};

const QuotesFormRaw = (props) => {
  const agentMetadata = props.agentMetadata;
  const baseUrl = props.baseUrl;
  const [gender, setGender] = useState(props.gender);
  const [age, setAge] = useState(props.age);
  const [email, setEmail] = useState(
    props.email == "undefined" ? "" : props.email
  );
  const [zip_code, setZipCode] = useState(props.zip_code);
  const [income, setIncome] = useState(props.income);
  const [county, setCounty] = useState(props.county);
  const [avaiableCounties, setAvaiableCounties] = useState(
    props.avaiableCounties
  );
  const [householdMembers, setHoulseholdMembers] = useState(
    props.householdMembers || []
  );
  const [spouse, setSpouse] = useState(props.spouse);
  const [tobacco, setTobacco] = useState(props.tobacco);
  const [subsidiesActive, setSubsidiesActive] = useState(true);
  const [householdSize, setHouseholdSize] = useState(props.householdSize);

  const [spousePopupState, setSpousePopupState] = useState({
    hovered: false,
    clicked: false,
  });

  const [dependentsPopupState, setDependentsPopupVisible] = useState({
    current: null,
    hovered: false,
    clicked: false,
  });

  function addDependant(dependent) {
    setDependentsPopupVisible({
      current: null,
      hovered: false,
      clicked: false,
    });
    setHoulseholdMembers([
      ...householdMembers.map((h, index) => ({
        ...h,
        open: false,
        index: Number(index),
      })),
      {
        index: householdMembers.length,
        ...dependent,
      },
    ]);
  }

  function updateDependant(dependent, index) {
    setDependentsPopupVisible({
      current: null,
      hovered: false,
      clicked: false,
    });

    if (_.isEmpty(dependent)) {
      setHoulseholdMembers(
        householdMembers.filter((d, i) => {
          return i !== index;
        })
      );
    } else {
      setHoulseholdMembers(
        householdMembers.map((h, i) => {
          return i === index ? { ...h, ...dependent } : { ...h };
        })
      );
    }
  }

  function addSpouse(spouse) {
    setSpousePopupState({
      clicked: false,
      hovered: false,
    });
    setSpouse(spouse);
  }

  const handleZipCodeChange = async (event) => {
    setZipCode(handleInputChange(event));
    if (
      !(event.target && event.target.value && event.target.value.length === 5)
    ) {
      setAvaiableCounties([]);
      setCounty(null);
      return;
    }

    const response = await axios({
      method: "get",
      url: `${baseUrl}/v1/zip-codes/${event.target.value}/counties`,
      headers: {
        "x-apollo-quotes-source": agentMetadata.id,
      },
    });

    if (
      response.status == 200 &&
      response.data.status != "400" &&
      response.data.length > 0
    ) {
      const counties = response.data;
      setCounty(counties[0]);
      setAvaiableCounties(counties);
    } else {
      setAvaiableCounties([]);
      setCounty(null);
    }
  };

  const handleHoverChange = (visible) => {
    setSpousePopupState({
      hovered: visible,
      clicked: spousePopupState.clicked,
    });

    setDependentsPopupVisible({
      current: null,
      hovered: false,
      clicked: false,
    });
  };

  const handleClickChange = (visible) => {
    setSpousePopupState({
      clicked: !spousePopupState.clicked,
      hovered: false,
    });

    setDependentsPopupVisible({
      current: null,
      hovered: false,
      clicked: false,
    });
  };

  const handleDependentHoverChange = (item, visible) => {
    setDependentsPopupVisible({
      current: item,
      hovered: visible,
      clicked: dependentsPopupState.clicked,
    });

    setSpousePopupState({
      clicked: false,
      hovered: false,
    });
  };

  const handleDependentClickChange = (item) => {
    setDependentsPopupVisible({
      current: item,
      hovered: false,
      clicked: !dependentsPopupState.clicked,
    });

    setSpousePopupState({
      clicked: false,
      hovered: false,
    });
  };

  const hidePopup = () => {
    setDependentsPopupVisible({
      current: null,
      hovered: false,
      clicked: false,
    });
    setSpousePopupState({
      clicked: false,
      hovered: false,
    });

    setSpousePopupState;
  };

  const getMinimumForSubsidies = () => {
    return subsidies[
      (1 + householdMembers.length + (spouse ? 1 : 0)).toString()
    ];
  };

  useEffect(() => {
    !avaiableCounties &&
      zip_code &&
      zip_code.length == 5 &&
      axios({
        method: "get",
        url: `${baseUrl}/v1/zip-codes/${zip_code}/counties`,
        headers: {
          "x-apollo-quotes-source": agentMetadata.id,
        },
      }).then((response) => {
        if (
          response.status == 200 &&
          response.data.status != "400" &&
          response.data.length > 0
        ) {
          const counties = response.data;
          setCounty(counties[0]);
          setAvaiableCounties(counties);
        } else {
          setAvaiableCounties([]);
          setCounty(null);
        }
      });
  });
  return (
    <Container className={css.form + " " + props.className}>
      <Container className={css.inputs}>
        <Container className={css.householdTitle}>
          Location
          <EnvironmentOutlined />
        </Container>
        <TextInput
          focusedlabel="Zip Code"
          value={zip_code}
          placeholder="Zip Code"
          pattern="[0-9]*"
          mask={/(^\d{5}$)|(^\d{5}-\d{4}$)/ }
          onChange={(event) => {
            handleZipCodeChange(event);
          }}
        />
        {avaiableCounties && avaiableCounties.length > 0 && (
          <Select
            before="County"
            className="fullwidth"
            size="large"
            options={avaiableCounties.map((a) => {
              return {
                label: a.name,
                value: a.name,
              };
            })}
            value={county ? county.name : null}
            defaultValue={avaiableCounties[0].name}
            onChange={(v) => {
              setCounty(avaiableCounties.filter((f) => f.name == v)[0]);
            }}
          />
        )}
        <Container>
          <br />

          <Container className={css.householdTitle}>
            Profile
            <UserOutlined />
          </Container>
        </Container>
        <Container className={css.emailAndGender}>
          <Container className={`${css.emailContainer}`}>
            <TextInput
              style={{ paddingRight: "80px" }}
              focusedlabel="Email"
              value={email}
              onChange={(event) => {
                setEmail(handleInputChange(event));
              }}
              type="email"
              placeholder="Email (Optional)"
              mask={/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}
            />
          </Container>
        </Container>

        <Container
          style={{
            marginBottom: "24px",
          }}
          className={css.genderAgeAndTobaccoContainer}
        >
          <Container className={css.ageContainer}>
            <TextInput
              style={{ width: "100%" }}
              focusedlabel="Age"
              value={age}
              onChange={(event) => {
                setAge(handleInputChange(event));
              }}
              type="number"
              placeholder="Age"
              min={0}
            />
          </Container>
          <Container className={css.genderContainer}>
            <Select
              className={css.dropDown}
              placeholder="Gender"
              size="large"
              value={gender}
              allowClear
              showArrow={!gender}
              onChange={setGender}
              dropdownStyle={{ background: "#fff" }}
            >
              <SelectOption className={`${css.bgWhite}`} value="Male">
                <Container
                  className={`${css.genderInputMaleContainer} ${
                    gender === "Male" ? css.maleActive : ""
                  }`}
                >
                  Male
                  <ManOutlined />
                </Container>
              </SelectOption>
              <SelectOption className={css.bgWhite} value="Female">
                <Container
                  className={`${css.genderInputFemaleContainer} ${
                    gender === "Female" ? css.femaleActive : ""
                  }`}
                >
                  Female
                  <WomanOutlined />
                </Container>
              </SelectOption>
            </Select>
          </Container>

          {
            <Container className={css.tobaccoContainer}>
              Tobacco User?
              <Checkbox
                style={{ transform: "scale(1.5) translate(6px,4px)" }}
                checked={tobacco}
                onChange={(e) => {
                  setTobacco(e.target.checked);
                }}
                className={css.tobaccoCheckbox}
              />
            </Container>
          }
        </Container>

        <Container className={css.addDependent}></Container>
        <Container className={css.householdContainer}>
          <Container className={css.householdTitle}>
            Household
            <HomeOutlined />
          </Container>
        </Container>
        <Container style={{ marginTop: "12px" }}>
          <TextInput
            style={{ paddingLeft: "200px" }}
            value={income}
            onChange={(event) => {
              setIncome(handleInputChange(event));
            }}
            type="number"
            before="Household Income"
          />
        </Container>
        <Container style={{ marginTop: "12px" }}>
          <TextInput
            style={{ paddingLeft: "200px" }}
            value={householdSize}
            onChange={(event) => {
              setHouseholdSize(handleInputChange(event));
            }}
            type="number"
            before="Household Size"
          />
        </Container>
      </Container>
      <Container className={css.householdMembers}>
        {householdMembers.map((hm, index) => {
          return (
            <Popover
              key={`pop-${index}`}
              visible={
                dependentsPopupState.current === index &&
                (dependentsPopupState.hovered || dependentsPopupState.clicked)
              }
              onVisibleChange={(visible) => {
                handleDependentHoverChange(index, visible);
              }}
              trigger="hover"
              placement="top"
              title={`Dependent ${Number(index + 1)}`}
              content={
                <AddDependentInput
                  {...householdMembers[Number(index)]}
                  hide={() => {
                    hidePopup();
                  }}
                  onSubmit={(d) => {
                    updateDependant(d, index);
                  }}
                />
              }
            >
              <Container
                onClick={() => {
                  handleDependentClickChange(index);
                }}
                className={`${css.householdMember} ${
                  spousePopupState.clicked ? css.active : ""
                }`}
              >
                <Container className={css.householdMemberIcon}>
                  {householdMembers[Number(index)].gender == "Female" ? (
                    <WomanOutlined className={css.femaleActive} />
                  ) : (
                    <ManOutlined className={css.maleActive} />
                  )}
                </Container>
                <Container>{`Dependent`}</Container>
                <Container className={css.dependentAgeContainer}>
                  {householdMembers[Number(index)].age}
                </Container>
              </Container>
            </Popover>
          );
        })}
        <Popover
          visible={spousePopupState.clicked}
          onVisibleChange={handleHoverChange}
          trigger="hover"
          placement="top"
          title="Spouse"
          content={
            <AddDependentInput
              hide={hidePopup}
              {...spouse}
              onSubmit={addSpouse}
            />
          }
        >
          <Container
            onClick={handleClickChange}
            className={`${css.householdMember} ${
              spousePopupState.clicked ? css.active : ""
            }`}
          >
            {spouse ? (
              <>
                <Container className={css.householdMemberIcon}>
                  {spouse.gender == "Female" ? (
                    <WomanOutlined className={css.femaleActive} />
                  ) : (
                    <ManOutlined className={css.maleActive} />
                  )}
                </Container>
                <Container>Spouse</Container>
                <Container className={css.dependentAgeContainer}>
                  {spouse.age}
                </Container>
              </>
            ) : (
              <>
                <Container className={css.householdMemberIcon}>
                  <UserAddOutlined />
                </Container>
                <Container>Add Spouse</Container>
              </>
            )}
          </Container>
        </Popover>

        <Popover
          visible={
            dependentsPopupState.current == null && dependentsPopupState.clicked
          }
          onVisibleChange={(visible) => {
            handleDependentHoverChange(null, visible);
          }}
          trigger="hover"
          placement="top"
          title="Dependent"
          content={
            <AddDependentInput
              hide={() => {
                hidePopup();
              }}
              onSubmit={addDependant}
            />
          }
        >
          <Container
            onClick={() => {
              handleDependentClickChange(null);
            }}
            className={`${css.householdMember} ${
              spousePopupState.clicked ? css.active : ""
            }`}
          >
            <Container className={css.householdMemberIcon}>
              <UsergroupAddOutlined />
            </Container>
            <Container>Add Dependent</Container>
          </Container>
        </Popover>
      </Container>
      {income &&
        income >= getMinimumForSubsidies() &&
        income <= getMinimumForSubsidies() * 4 && (
          <Container className={css.mayQualify}>
            <Container className={css.qualifyForSubsidies}>
              You may qualify for subsidies
              <Container className={css.subsidies}>
                <Container className={css.qualifyForSubsidies}>
                  <Container className={css.qualifyForSubsidiesText}>
                    <Checkbox
                      checked={subsidiesActive}
                      onChange={(e) => {
                        setSubsidiesActive(e.target.checked);
                      }}
                      className={css.subsidiesCheckbox}
                    />
                    Get quote with subsidies
                  </Container>
                </Container>
              </Container>
            </Container>

            <Popover
              placement="top"
              content={
                <Container>
                  With the provided income and household information, you might
                  qualify for healthcare subsidies. For more information visit:{" "}
                  <a
                    target="_blank"
                    href="https://www.healthcare.gov/glossary/subsidized-coverage/"
                  >
                    https://www.healthcare.gov/glossary/subsidized-coverage/
                  </a>
                </Container>
              }
              title=""
            >
              <QuestionCircleOutlined className={css.questionIcon} />
            </Popover>
          </Container>
        )}

      {county && age && income && gender ? (
        <Link
          onClick={() => {
            props.onSubmit && props.onSubmit();
          }}
          to={`/quotes?income=${income}&gender=${gender}&age=${age}&email=${email}&zip_code=${zip_code}&fips_code=${
            county.fips_code
          }&state=${
            county.state
          }&uses_tobacco=${!!tobacco}&subsidy_eligible=${!!subsidiesActive}${
            spouse ? "&spouse=" + JSON.stringify(spouse) : ""
          }&dependents=${
            householdMembers ? JSON.stringify(householdMembers) : "{}"
          }&household_size=${
            householdSize ||
            1 +
              (spouse ? 1 : 0) +
              (householdMembers ? householdMembers.length : 0)
          }`}
          className={`${css.apolloButtonContainer}`}
        >
          <Button onClick={() => {}} type="primary">
            GET A QUOTE
          </Button>
        </Link>
      ) : (
        <div
          style={{ alignSelf: "center" }}
          className={`${css.apolloButtonContainer}`}
        >
          <Button style={{ background: "#ccc" }} disabled onClick={() => {}}>
            GET A QUOTE
          </Button>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ baseUrl }) => {
  return {
    baseUrl,
  };
};

export default connect(mapStateToProps)(QuotesFormRaw);
