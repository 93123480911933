import React from "react";

import { Button, Container, Popup } from "../../../../../../";

import css from "./style.module.scss";

export default (props) => {
  return (
    <Popup
      title={
        <Container effects={[1]} className={`${css.picwellContainer}`}>
          Bio
        </Container>
      }
      visible={props.visible}
      onClose={props.onClose}
    >
      <Container className={css.contentContainer}>
        {props.data?.site?.siteMetadata?.agent?.bio}
      </Container>
    </Popup>
  );
};
