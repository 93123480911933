import exporting from "./exporting";
import rendering from "./rendering";

export * from "./exporting";
export * from "./rendering";
export * from "./formatting";

export const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

export default {
  handleInputChange,
  exporting,
  rendering,
};
