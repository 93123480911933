import React, { useState, useEffect } from "react";
import {
  Badge,
  Checkbox,
  Container,
  ShareAltOutlined,
  Icon,
  Tooltip,
  Select,
} from "../../..";
import { Button, Popover, Radio, Space, Spin, Skeleton } from "antd";
import { formatCurrency } from "../../../../utils";
import BenefitsList from "./benefits-list";

import css from "./style.module.scss";

import axios from "axios";

export const metalLevelColors = {
  Bronze: "#6A3805",
  Catastrophic: "#575988",
  Gold: "#AF9500",
  Platinum: "#3B3B41",
  Silver: "#B4B4B4",
};

const PrefferedRateComponent = (props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [priorCoverage, setPriorCoverage] = useState(props.priorCoverage);
  const [prefferedRate, setPrefferedRate] = useState(props.preferredRate);
  const [loading, setLoading] = useState(true);

  return (
    <Container className={`${css.prefferedRate}  `}>
      <Popover
        placement="bottom"
        trigger="click"
        title={
          <Container
            style={{
              display: "flex",
              paddingLeft: "12px",
              justifyContent: "space-between",
            }}
          >
            Check if you qualify for a lower premium
            <Container
              onPress={() => {
                setPopupVisible(false);
              }}
            >
              X
            </Container>
          </Container>
        }
        visible={popupVisible}
        onVisibleChange={(visible) => {
          setPopupVisible(visible);
        }}
        content={
          <Container className={css.popoverContent}>
            {/* <Container className={ css.popupInputsContainer}>
          <Container className={css.popupInputContainer}>
            <Container className={css.popupInputLabel}>Prior Coverage</Container>
          <Container>
            <Select options={ [{
              label:"yes",
              value: "yes",

            },
            { label:"no", value:"no"}
            ]}/>
          </Container>

        </Container>
          <Container className= { css.popupInputContainer}>
            <Container className={css.popupInputLabel}>Preffered Premium</Container>
            <Container> <Checkbox/></Container>

          </Container>
      </Container> */}
            <Container>
              <Container className={css.popupDescription}>
                <Container className={css.popupDescriptionLabel}>
                  Prior Coverage
                </Container>

                <Container
                  className={`${css.popupDescriptionText}  ${
                    priorCoverage !== undefined ? css.questionOk : ""
                  } `}
                >
                  All adult applicants had other major medical health insurance
                  (including Short Term) for at least 9 months within the last
                  12 months
                  <br />
                  <br />
                  Do you have prior coverage?
                </Container>

                <Container className={css.popupRadio}>
                  <Radio.Group
                    value={priorCoverage}
                    onChange={async (v) => {
                      const baseUrl =
                        props.siteData?.site?.siteMetadata?.baseUrl;
                      console.log("v", props);
                      setPriorCoverage(v.target.value);

                      if (
                        (v.target.value && props.uses_tobacco !== "true") ||
                        props.spouse_uses_tobacco == "true"
                      ) {
                        try {
                          setLoading(true);
                          const response = await axios({
                            method: "get",
                            url: `${baseUrl}/v1/enrollment/national-general/${props.quote_id}?state=${props.state}&plan_id=${props.plan_id.value}&preferred=true`,
                            headers: {
                              "x-apollo-quotes-source":
                                props.siteData?.site?.siteMetadata?.agent?.id,
                              baseUrl,
                            },
                          });

                          const questions = response.data?.applications[0]?.generalQuestions?.map(
                            (q) => q.questionText
                          );
                          questions.shift();
                          setQuestions(questions || []);
                        } finally {
                          setLoading(false);
                        }
                      } else {
                      }
                    }}
                    buttonStyle="solid"
                    size="large"
                  >
                    <Space>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Container>
              </Container>
            </Container>
            <Container>
              {priorCoverage === 1 &&
                props.uses_tobacco !== "true" &&
                (loading ? (
                  <Container className={css.spin}>
                    {" "}
                    <Spin />{" "}
                  </Container>
                ) : (
                  <Container className={css.popupDescription}>
                    {priorCoverage == 1 && !prefferedRate && (
                      <Container className={css.discount}>
                        <span>
                          $
                          {props.premium_options
                            ? props.premium_options.options[1].premium
                            : 0}
                        </span>{" "}
                        rate available
                      </Container>
                    )}
                    <Container className={css.popupDescriptionLabel}>
                      Preferred Premium
                    </Container>
                    <Container className={css.popupDescriptionSection}>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -All adult applicants had other major medical health
                        insurance (including Short Term) for at least 9 months
                        within the last 12 months.
                      </Container>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -All the male applicants must weight 240 pounds or less
                        and all female applicants must weight 190 pounds or
                        less.
                      </Container>
                      <Container
                        className={`${css.popupDescriptionText}  ${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        -You have to answer <span>no</span> to the following
                        questions{" "}
                      </Container>
                      <Container>
                        {questions.map((q) => {
                          return (
                            <Container
                              style={{ margin: "12px", display: "flex" }}
                            >
                              <Container style={{ marginRight: "6px" }}>
                                •
                              </Container>
                              <div dangerouslySetInnerHTML={{ __html: q }} />
                            </Container>
                          );
                        })}
                      </Container>

                      <Container
                        className={`${
                          prefferedRate !== undefined ? css.questionOk : ""
                        } `}
                      >
                        Do you qualify for a preferred premium?
                      </Container>
                      <Container style={{ marginBottom: "12px" }} />
                      <Container className={css.popupRadio}>
                        <Radio.Group
                          value={prefferedRate}
                          onChange={(v) => {
                            setPrefferedRate(v.target.value);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Space>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Container>
                    </Container>
                    {priorCoverage == 1 && prefferedRate == 1 && (
                      <Container className={css.discount}>
                        <span>
                          ${" "}
                          {priorCoverage
                            ? prefferedRate
                              ? props.premium_options.lowest_premium
                              : props.premium_options.options[1].premium
                            : 0}
                        </span>{" "}
                        rate available
                      </Container>
                    )}
                  </Container>
                ))}
            </Container>
            <Container
              onPress={() => {
                props.onPreferredRateSubmit &&
                  props.onPreferredRateSubmit({
                    priorCoverage,
                    prefferedRate,
                  });
                setPopupVisible(false);
              }}
              className={css.submitButton}
            >
              <Button type="primary"> Submit</Button>
            </Container>
          </Container>
        }
      >
        <Container
          onPress={() => {
            setPopupVisible(true);
          }}
        >
          {props.specialRates &&
          (props.specialRates.priorCoverage == 1 ||
            props.specialRates.prefferedRate == 1) ? (
            <Container>
              $
              {props.specialRates.prefferedRate
                ? props.premium_options.lowest_premium
                : props.premium_options.options[1].premium}{" "}
              premium available.
            </Container>
          ) : (
            <Container>
              You may qualify for a $
              {props.premium_options
                ? props.uses_tobacco
                  ? props.premium_options.options[1].premium
                  : props.premium_options.lowest_premium
                : 0}{" "}
              premium.
            </Container>
          )}
        </Container>
      </Popover>
    </Container>
  );
};

export const OfferCardSkeleton = () => {
  return (
    <Container className={css.offerCardContainer}>
      <Container
        style={{ borderColor: "#EEE", padding: "24px" }}
        className={css.infoContainer}
      >
        <Skeleton title={false} paragraph={{ rows: 4, width: "100%" }} active />
      </Container>
    </Container>
  );
};

export default (props) => {
  const {
    plan_premium_only,
    benefits,
    hideDeductible,
    hideHeader,
    metal_level,
    innerStyle,
    style,
    score,
    carrier,
    imgid,
    exportImage,
    pdf,
    onSelectChange,
    deductible,
    id,
    plan_name,
    premium,
    selected,
    info,
    showSelection,
  } = props;
  let iconProps = {};

  if (info && info.label == "Metal Level")
    iconProps = { icon: "star-flag", fill: metalLevelColors[info.value] };
  if (info && info.value == "Association")
    iconProps = { icon: "association", fill: "#F7971C" };
  if (info && info.value == "NHICSupplemental")
    iconProps = { icon: "shield-filled", fill: "#F7971C" };
  if (info && info.value == "STM")
    iconProps = { icon: "shield-outlined", fill: "#F7971C" };
  if (info && info.value == "Basic")
    iconProps = { icon: "s-outlined", fill: "#1B75BC" };
  if (info && info.value == "Crown")
    iconProps = { icon: "crown", fill: "#1B75BC" };
  if (info && info.value == "Enhanced")
    iconProps = { icon: "s-filled", fill: "#1B75BC" };
  if (info && info.value == "Short Term Health Insurance")
    iconProps = { fill: "#575988", icon: "short-term" };
  if (info && info.value == "Dental Insurance")
    iconProps = { icon: "dental-insurance", fill: "#053555" };
  if (info && info.value == "HealthiestYou")
    iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
  if (info && info.value == "Hospital Indemnity")
    iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
  if (info && info.value == "Supplemental Indemnity")
    iconProps = { icon: "supplemental", fill: "#F3BF38" };
  if (info && info.value == "HealthiestYou")
    iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
  if (info && info.value == "Term Life")
    iconProps = { icon: "term-life", fill: "#1B75BC" };
  if (info && info.value == "Vision")
    iconProps = { icon: "vision", fill: "#1C4C99" };
  if (info && info.value == "Accident")
    iconProps = { icon: "accident", fill: "#F7971C" };
  if (info && info.value == "Critical Illness")
    iconProps = { icon: "critical-illness", fill: "#77C3FF" };
  if (plan_name.value == "Aspire Dental")
    iconProps = { icon: "aspire-dental", fill: "#77C3FF" };
  return (
    <Container
      style={style}
      id={imgid}
      className={`${css.offerCardContainer} ${pdf ? css.pdf : css.screen}`}
    >
      <Container
        style={innerStyle}
        className={`${css.infoContainer} ${
          pdf ? css.infoContainerScreenOnly : ""
        } ${
          score
            ? score > 50
              ? css.green
              : score > 25
              ? css.yellow
              : css.red
            : ""
        }`}
      >
        {!hideHeader && (
          <Container
            className={`${css.planNames} ${
              selected ? css.planNamesSelected : ""
            }`}
          >
            <Tooltip title={carrier.value}>
              <Container className={css.issuerName}>
                {carrier.value} {score ? `(score: ${"" + (100 - score)})` : ``}
              </Container>
            </Tooltip>
            <Container className={css.planName}>
              <Tooltip title={plan_name.value}>{plan_name.value}</Tooltip>
            </Container>
          </Container>
        )}

        <Container className={css.info2}>
          {
            <Icon
              style={{
                ...(hideHeader ? { transform: "translateY(-90px)" } : {}),
              }}
              {...iconProps}
            />
          }

          <Container className={`${css.planTypePrice} ${css.marginRight24px}`}>
            <Container
              className={css.planCategory}
              style={{ color: iconProps.fill }}
            >
              {info?.value}
            </Container>

            {false && (
              <Container className={css.planType}>{premium.label}</Container>
            )}
            <Container
              className={`${css.planPrice} ${
                props.specialRates &&
                info &&
                info.value == "STM" &&
                props.specialRates.priorCoverage == 1
                  ? css.priorCoverage
                  : ""
              } `}
            >
              {formatCurrency(premium.value) + " / Month"}
              {premium.premium_without_subsidy && (
                <Container className={css.premiumWithoutSubsidy}>
                  $ {premium.premium_without_subsidy}
                </Container>
              )}
            </Container>
          </Container>
        </Container>
        {carrier.value == "National General" && info && info.value == "STM" && (
          <PrefferedRateComponent {...props} />
        )}
        {!pdf && (
          <Container>
            <BenefitsList
              plan={props}
              pdf={pdf}
              benefits={benefits}
              id={imgid}
            />
          </Container>
        )}
        {!pdf && (
          <Container className={css.info3}>
            {info ? (
              <Container className={css.info3}>
                {deductible.label} {deductible.value ? ":" : ""}
                <Container className={css.metalLevelText}>
                  {" "}
                  {deductible.value && formatCurrency(deductible.value)}
                </Container>
              </Container>
            ) : (
              <Container className={css.info3}>
                <Badge
                  style={{ height: "18px", marginTop: "4px" }}
                  color={"#fff"}
                />
              </Container>
            )}
          </Container>
        )}
        {!pdf && onSelectChange && (
          <Container
            onPress={() => {
              onSelectChange &&
                onSelectChange({
                  id,
                  checked: !selected,
                });
            }}
            className={`${css.info4} ${selected ? css.selected : ""}`}
          >
            {/* Interested
            {selected ? <HeartFilled /> : <HeartOutlined />} */}
            {selected ? "Remove" : "Add to Cart"}
            {selected ? (
              <Icon
                style={{ marginLeft: "6px", width: "36px", height: "36px" }}
                fill={"#06315E"}
                icon="remove-from-cart"
              />
            ) : (
              <Icon
                style={{ marginLeft: "6px", width: "36px", height: "36px" }}
                fill={"#27A0FF"}
                icon={"add-to-cart"}
              />
            )}
          </Container>
        )}
      </Container>
    </Container>
  );
};
