import React from "react";
import { Avatar, Container, Logo } from "../../components";
import css from "./style.module.scss";

const Header = (props) => {
  const { data } = props;

  return (
    <header className={css.mainContainer}>
      <Container className={css.logoContainer}>
        <Container className={css.logoContainer}>
          <Container className={css.logo}>
            <svg>
              <use xlinkHref="/sprite.svg#apollo-icon" />
            </svg>
          </Container>
          <Container className={css.logoText}>
            <span>Apollo </span> Quotes
          </Container>
        </Container>
      </Container>

      <Container className={css.agentName}>
        {data && data.site.siteMetadata.agent.name}
      </Container>

      <Container className={css.agentTitle}>
        {data && data.site.siteMetadata.agent.title}
      </Container>
      <Container className={css.bioButton}>View Bio</Container>
      <Container className={css.headerRight}>
        <Container className={css.menuContainer}>
          {
            //<QuoteMobileIcon className={css.quoteMobileIcon} />
            //<QuoteWebIcon className={css.quoteWebIcon} />
          }
        </Container>
      </Container>
      <Container className={css.avatarContainer}>
        <Avatar
          className={css.avatar}
          link=""
          round="true"
          src={data?.site?.siteMetadata?.agent?.imageSrc}
        />
      </Container>
    </header>
  );
};

export default Header;
