import React from "react";

import { OfferCard } from "../components";

import css from "./style.module.scss";

import { v4 as uuidv4 } from "uuid";

import { render, exportAsPDF, exportAsBase64PDF } from "../../../utils";
import axios from "axios";

export const exportPDF = (offers, agent, data, uuid) => {
  const buildPDFPage = async (currentItem, currentPages = []) => {
    const page = Math.ceil(currentItem / 1) + 1;
    const slice =
      currentItem < offers.length - 0
        ? currentItem + 1
        : currentItem + (offers.length - currentItem);
    const header = (
      <div className={`${css.headerStyle}`}>
        <div>
          <img className={css.imageStyle} src={agent.image} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "12px",
          }}
        >
          <div className={`${css.defaultStyle} ${css.nameStyle}`}>
            {agent.name}
          </div>
          <div className={`${css.defaultStyle} ${css.titleStyle}`}>
            {agent.title}
          </div>
          <div className={`${css.defaultStyle} ${css.underscoreStyle}`} />
          <div className={`${css.defaultStyle}`}>{agent.email}</div>
          <div className={`${css.defaultStyle}`}>{agent.website}</div>
          <div className={`${css.defaultStyle}`}>{agent.phone}</div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={css.qrCodeStyle}>
          <canvas className={"replace-with-qr-code"} />
        </div>
      </div>
    );
    const cards = offers
      .filter((f) => f.info?.label === "Metal Level")
      .map((card, index) => {
        return (
          <tr
            key={uuidv4()}
            className={`${Number(index) % 2 == 0 ? css.odd : css.even}`}
          >
            <td> {card.plan_name?.value}</td>
            <td> {card.carrier?.value}</td>
            <td> {card.info?.value}</td>
            <td> {card.premium?.value}</td>
            <td> {card.deductible?.value}</td>
          </tr>
        );
      });

    const natCards = offers
      .filter((f) => f.info?.label === "Product Type")
      .map((card, index) => {
        return (
          <tr
            key={uuidv4()}
            className={`${Number(index) % 2 == 0 ? css.odd : css.even}`}
          >
            <td> {card.plan_name?.value}</td>
            <td> {card.carrier?.value}</td>
            <td> {card.info?.value}</td>
            <td> {card.premium?.value}</td>
            <td> {card.deductible?.value}</td>
          </tr>
        );
      });

    const oneShareCards = offers
      .filter((f) => f.info?.label === "Tier")
      .map((card, index) => {
        return (
          <tr
            key={uuidv4()}
            className={`${Number(index) % 2 == 0 ? css.odd : css.even}`}
          >
            <td> {card.plan_name?.value}</td>
            <td> {card.carrier?.value}</td>
            <td> {card.info?.value}</td>
            <td> {card.premium?.value}</td>
            <td> {card.deductible?.value}</td>
          </tr>
        );
      });

    const unitedCards = offers
      .filter((f) => f.info?.label === "Plan Type")
      .map((card, index) => {
        return (
          <tr
            key={uuidv4()}
            className={`${Number(index) % 2 == 0 ? css.odd : css.even}`}
          >
            <td> {card.plan_name?.value}</td>
            <td> {card.carrier?.value}</td>
            <td> {card.info?.value}</td>
            <td> {card.premium?.value}</td>
            <td> {card.deductible?.value}</td>
          </tr>
        );
      });

    const aspireCards = offers
      .filter((f) => !f.info)
      .map((card, index) => {
        return (
          <tr
            key={uuidv4()}
            className={`${Number(index) % 2 == 0 ? css.odd : css.even}`}
          >
            <td> {card.plan_name?.value}</td>
            <td> {card.carrier?.value}</td>
            <td> {card.premium?.value}</td>
          </tr>
        );
      });
    //<OfferCard pdf={true} selected={false} key={uuidv4()} {...card} />)

    const footer = (
      <div
        style={{
          color: "black",
          padding: "2px 28px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          position: "absolute",
          bottom: "-0px",
          height: "36px",
          width: "595px",
        }}
      >
        <div>{`Results from ${new Date().toLocaleDateString()}`}</div>
        <div>{`Page ${page} of ${Math.ceil(offers.length / 1)}`}</div>
      </div>
    );

    const node = document.getElementById("pdf");

    await render(
      <div
        className={css.pdfdiv}
        style={{ position: "fixed", left: "-2000px", width: "595px" }}
        id={"rendered-pdf"}
      >
        {
          <div
            key={uuidv4()}
            style={{
              position: "relative",
              height: "842px",
              maxHeight: "842px",
              width: "595px",
              maxWidth: "595px",
            }}
          >
            {header}
            {cards.length > 0 && (
              <div style={{}}>
                <div>
                  <div className={css.tableTitle}>
                    Plans from the Marketplace
                  </div>
                  <table>
                    <tr>
                      <th> Name</th>
                      <th> Carrier</th>
                      <th> Metal level</th>
                      <th> Premium</th>
                      <th> Deductible</th>
                    </tr>

                    {cards}
                  </table>
                </div>
              </div>
            )}

            {natCards.length > 0 && (
              <div>
                <div>
                  <div className={css.tableTitle}>
                    Plans from National General
                  </div>
                  <table>
                    <tr>
                      <th> Name</th>
                      <th> Carrier</th>
                      <th> Product Type</th>
                      <th> Price</th>
                      <th> Deductible</th>
                    </tr>

                    {natCards}
                  </table>
                </div>
              </div>
            )}

            {oneShareCards.length > 0 && (
              <div>
                <div>
                  <div className={css.tableTitle}>Plans from One Share</div>
                  <table>
                    <tr>
                      <th> Name</th>
                      <th> Carrier</th>
                      <th> Tier</th>
                      <th> Price</th>
                      <th> ISA</th>
                    </tr>

                    {oneShareCards}
                  </table>
                </div>
              </div>
            )}

            {unitedCards.length > 0 && (
              <div style={{}}>
                <div>
                  <div className={css.tableTitle}>
                    Plans from United Healthcare
                  </div>
                  <table>
                    <tr>
                      <th> Name</th>
                      <th> Carrier</th>
                      <th> Plan Type</th>
                      <th> Rate</th>
                      <th> Deductible</th>
                    </tr>

                    {unitedCards}
                  </table>
                </div>
              </div>
            )}

            {aspireCards.length > -0 && (
              <div style={{}}>
                <div>
                  <div className={css.tableTitle}>Plans from Aspire Dental</div>
                  <table>
                    <tr>
                      <th> Name</th>
                      <th> Carrier</th>
                      <th> Premium</th>
                    </tr>
                    {aspireCards}
                  </table>
                </div>
              </div>
            )}
          </div>
        }
      </div>,
      node
    );

    const newPage = node.innerHTML.slice();
    await render(<div />, node);

    //if (currentItem < offers.length - 1) {
    //    buildPDFPage(currentItem + 1, [...currentPages, newPage])
    //} else {
    if (data) {
      const resp = await axios({
        method: "post",
        url:
          `${"https://temp.enrollment.qa.apolloquotes.com"}/v1/quotes/` + uuid,
        data: {
          ...data,
          generated_doc: await exportAsBase64PDF([...currentPages, newPage]),
        },
        headers: {
          "Apollo-Quotes-Source": agent.id,
        },
      });
    } else {
      exportAsPDF([...currentPages, newPage]);
    }
    // }
  };

  buildPDFPage(0);
};
